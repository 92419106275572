import { useEffect, useState } from 'react';
import './bootstrap.min.css';
import './style.css';

import complimenti from './cachedImages/complimenti.png';
import sconfitta from './cachedImages/sconfitta.png';
import cocktail from './cachedImages/cocktail.png';
import peccato from './cachedImages/peccato.png';
import restartIcon from './cachedImages/restart.png';
import home from './cachedImages/home.png';
import start from './cachedImages/start.png';
import logobianco from './cachedImages/logo-bianco.png';
import logonero from './cachedImages/logo-nero.png';
import background from './cachedImages/background.jpg';

function App() {

  const indexToLetter = [
    'A',
    'B',
    'C',
    'D'
  ]
  const questions = [
    {
      quest:'Quale dei seguenti ingredienti viene utilizzato per realizzare la Molinari Extra?',
      answers:[
        {label:'Fiori di sambuco', correct:false},
        {label:'Foglie di menta', correct:false},
        {label:'Semi di anice stellato', correct:true},
        {label:'Semi di finocchio', correct:false}
      ],
    },
    {
      quest:'Quale cocktail Molinari viene preparato all’interno della mug in rame?',
      answers:[
        {label:'Molinari Mule', correct:true},
        {label:'Molinari Paloma', correct:false},
        {label:'Italiano', correct:false},
        {label:'Molinari Mojito', correct:false}
      ],
    },
    {
      quest:'Dove è stata fondata la Molinari?',
      answers:[
        {label:'Roma', correct:false},
        {label:'Civitavecchia', correct:true},
        {label:'Milano', correct:false},
        {label:'Torino', correct:false}
      ],
    }
  ].sort((a, b) => 0.5 - Math.random());

  const [page, setPage] = useState(0);
  const [points, setPoints] = useState(0);
  const [currentQuestions, setCurrentQuestions] = useState(questions);
  const [current, setCurrent] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [answered, setAnswered] = useState({correct:null, item:null});
  const [disabled, setDisabled] = useState(false)
  
  

  const nextPage = () => {
    setPage(page+1);
  }

  const answer = (resp) => {

    let ans = [...answers];
    setDisabled(true)
    if (resp) {
      setPoints(points+1);
      ans.push(1);
      setAnswers(ans);
      
    } else {
      
      ans.push(0);
      setAnswers(ans);
     
    }

    
      setTimeout(()=>{
        setCurrent(current+1);
        setDisabled(false)
        if (ans.length === 3) {
          setPage(page+1);
        }
      },1000);
      
    
  }

  const restart = () => {
    setAnswers([]);
    setCurrent(0);
    shuffleCurrent()
    setPoints(0);
    setPage(0);
  }

  const shuffleCurrent = () => {
    let shuffled = questions.sort((a, b) => 0.5 - Math.random());
    shuffled.map((el)=> {
      let sh = el.answers.sort((a, b) => 0.5 - Math.random());
      return {...el, answers:sh}
      }
    )
    setCurrentQuestions(shuffled);
  }

  useEffect(()=>console.log(answered), [answered])

  return (
    <div className="App">

      { page === 0 ? (<>
      <div className="background d-flex align-items-center justify-content-center" id="first">
        <img src={background} alt="background" style={{width:'100%', objectFit:'cover', position:'absolute', top:0, left:0, height:'100vh', opacity:'.25', mixBlendMode:'luminosity'}} />
        <div className="logoFixed">
          <img src={logobianco} alt="logo molinari bianco" />
        </div>
        <div className="align-items-center justify-content-center text-center content">
          <p className='text-uppercase'><strong>Hai l'età legale<br />per il consumo di alcolici?</strong></p>
          <p>
            <button type="button" onClick={()=>nextPage()} className="btn btn-icon btn-outline mr-5">Si</button>
            <button type="button" className="btn btn-icon btn-outline">No</button>
          </p>
        </div>
      </div>
      </>) : 
      (<div className="d-flex align-items-center justify-content-center page-base">
        <div className="logoFixed">
          <img src={logonero} alt="logo molinari nero" />
        </div>
        <div className="content" style={{width:"100%"}}>
          <div className="container-fluid">
              <div className="row">
                <div className="col-10 offset-1">
                {page === 1 ? (
                  <div className="startscreen position-relative">
                    <img src={background} alt="background" style={{width:'100%', objectFit:'cover', position:'absolute', top:0, left:0, height:'100%', zIndex:1}} />
                    <button type="button" onClick={nextPage} className="btn btn-none m-0 p-0 position-relative" style={{zIndex:2}}><img src={start} alt="Start" width="45%" /></button>
                  </div>) : null }
                {page === 2 ? (<>
                  <div className="card" style={{width:'100%',background:'#ececec'}}>
                    <div className="card-body">
                      <div className="boxed-entities">
                        <p>
                          <span className={`${current === 0 ? 'rounded-box' : null} ${answers.length >= 1 && answers[0] === 1 ? 'text-success' : answers[0] === 0 ? 'text-danger' : ''}`}>1</span>
                          <span className={`${current === 1 ? 'rounded-box' : null} ${answers.length >= 2 && answers[1] === 1 ? 'text-success' : answers[1] === 0 ? 'text-danger' : ''}`}>2</span>
                          <span className={`${current === 2 ? 'rounded-box' : null} ${answers.length >= 3 && answers[2] === 1 ? 'text-success' : answers[2] === 0 ? 'text-danger' : ''}`}>3</span>
                        </p>
                      </div>



                    {currentQuestions.map((e, i)=>{
                      if (i === current)
                        return (
                          <div>
                            <div style={{height:"20vh"}}>
                              <h2 className='text-center text-uppercase'>{e.quest}</h2>
                            </div>
                            <div className="answers">
                              {e.answers.map((el, j)=>(

                                
                                <button 
                                  type="button" 
                                  className={`btn ${answers.length === i+1 && el.correct ? 'correct' : answered?.item === j ? (answered.correct ? 'correct' : 'wrong') : ''}`}
                                               
                                  disabled={disabled} 
                                  onClick={()=>{
                                    console.log(j);
                                      setAnswered({correct:el.correct, item:j})
                                      answer(el.correct);
                                     
                                  }}>{indexToLetter[j]} - {el.label}</button>
                              ))}
                            </div>  
                          </div>
                        )

                      return null;
                      }
                    )}

</div>
                    <div>
                      <button type="button" className="btn btn-icon" onClick={restart}><img src={restartIcon} alt="restart" width="50" /></button>
                    </div>
                  </div>
                  </>) : null}

{page === 3 ? (<>
  {points > 1 ? (<div className='results text-center'>
    <p><img className='img-inside' src={cocktail} alt="cocktail" /></p>
    <p><img className='img-inside' src={complimenti} alt="complimenti" /></p>
    <p className="mt-5"><button type="button" className="btn btn-icon" onClick={restart}><img src={home} alt="restart" width="50" /></button></p>
  </div>) : (<div className='results text-center'>
    <p><img className='img-inside' src={sconfitta} alt="sconfitta" /></p>
    <p><img className='img-inside' src={peccato} alt="peccato" /></p>
    <p className="mt-5"><button type="button" className="btn btn-icon" onClick={restart}><img src={home} alt="restart" width="50" /></button></p>
  </div>)}
</>) : null}
                </div>
                
              </div>
              
          </div>
          
        </div>
      </div>) 
      }
     
    </div>
  );
}

export default App;
